body{
    background: $body-bg !important;
}

.header{
    z-index: 1;
}

.oculto{
    display:none;
}

.tabs-title.second{
margin-left:20px;
}

.wrapper {
    height:100vh;
    display:flex;
    flex-direction: column;
}

header, footer {
    height: auto;
}

main {
    flex:1;
}

.container{
    background: white; 
    z-index: 0;
    padding-top: 150px;
    height: 100%;
    top:0;
    padding-right: 30px;
    padding-left: 30px;
}

footer{
    width:100%;
    z-index: 1;
    background-color: $azulOscuro;
    color: $blanco;
}

.page-title{
    display:none;
    position: absolute;
    color: $blanco;
    background-color:  $azulMedio;
    text-align:center;
    border-radius: 0px 0px 40px 40px;    
    left: 25%;
    width:50%;
}

.menu-lateral{
    position: fixed;
    max-width: 270px;
    width:20%;

}

.top-bar{
    color:white;
    }

.top-bar a {
    color:white;
    font-weight: normal;
}

.top-bar a:hover {
    background: $azulOscuro;
    color: $naranjaOscuro;
}


.logo-header{
     margin-top: 28px;
}

@media (max-width: 1070px) {
    .top-bar{
        h1{
            font-size:2.0em;
        }
    }
}

@media (max-width: 740px) {
    .top-bar{
        h1{
            font-size:1.5em;
        }
    }
}

@media (max-width: 550px) {
    .top-bar{
        h1{
            font-size:0.9em;
            font-weight:bold;
        }
    }
}

@media (max-width: 640px) {
   .page-title{
        display:block;
    }
}



header{
    height: 120px;
    position: fixed;
    width: 100%;
    z-index: 100;
}    


.botones_doc .boton{
    cursor: pointer;
}

.botones_doc .boton.no_selected{
    opacity: 0.3;
}



.menu{
    position: relative;
bottom: -6px;
}

.imagen_manual{
    background: url('/public/img/manual.png');
    width:200px;
    height:200px;
    margin:auto;
    background-size: 100% 100%;
    background-position:center;
    background-repeat: no-repeat;
}

.imagen_manual:hover, .boton.selected .imagen_manual{
    background: url('/public/img/manual_selected.png');
}

.imagen_java{
    background: url('/public/img/javadoc.png');
    width:200px;
    height:200px;
    margin:auto;
    background-size: 100% 100%;
    background-position:center;
    background-repeat: no-repeat;
}

.imagen_java:hover, .boton.selected .imagen_java{
    background: url('/public/img/javadoc_selected.png');
}

.height_200{
    height:200px;
    vertical-align: middle;
    line-height: 200px;
}
.height_200 img{
    max-height:200px;
}

.sticky.doc_manual a:hover{
    color: $naranjaOscuro;
}

.lateral_menu{
    width: 25%;
}

.lateral_menu .maxheight{
    overflow-y: auto;
    height: calc(100vh - 123px);
}

.lateral_menu.fixed{
    width: 100%;
    max-width: 285px;
    position:fixed;
    top:115px;
}


@media (max-width: 1080px) {
    .lateral_menu{
        width: 100%;
    }

   .lateral_menu.fixed{
        max-width: 100%;
        position:relative;
        top: 0px;
    }
    .lateral_menu .maxheight{
        overflow-y: hidden;
        height: auto;
    }
    .contenido_manual{
        width:100% !important;
    }
}




.active{
    color:#F00;
}

.imag_code{
   margin:auto;
   text-align:center;
}

.msg_status{
    transition: all 1s ease-in-out;
}
.msg_status.hide{
    display:none;
}

