
// Variables
$body-bg: #f5f5f5;
$azulOscuro: #4d7a97;
$azulMedio: #4d7a97;
$blanco: #FFF;
$grisClaro: #aaa;

$naranja: #f8981d;
$naranjaOscuro: #e68a00;

$bordeGris: $grisClaro;



